// eslint-disable-next-line import/no-unresolved
import screenshot from "src/images/PaintMatch/3.webp";

import ExternalLink from "../../Components/ExternalLink";
import GitHubBadge from "../../Components/GitHubBadge";
import ItchIoBadge from "../../Components/ItchIoBadge";
import MobileStoreBadges from "../../Components/MobileStoreBadges";
import PortfolioPage from "../../Components/PortfolioPage";
import Bullets from "../../Components/Typography/Bullets";
import Paragraph from "../../Components/Typography/Paragraph";
import MKTypography from "../../material-kit/MKTypography";
import { PaintMatchRoute } from "../../Routes/MenuRoutes";

export default function PaintMatch({ headerOnly }) {
    return (
        <PortfolioPage
            title="Paint Match"
            subtitle="Color blending puzzle game"
            description="Match target colors by blending paint droplets and enhance your understanding of color theory along the way."
            screenshot={screenshot}
            videoId="2_frnlZEvt0?si=GSgHynna2PR39HiV"
            route={PaintMatchRoute}
            headerOnly={headerOnly}
        >
            <Paragraph>
                In each level, you are presented with a specific target color
                and a set of color droplets to mix. Your objective is to
                recreate the target color as precisely as possible using the
                provided droplets. Your goal is to replicate the target color as
                accurately as possible using the available droplets. As you
                advance, the difficulty escalates, progressively building your
                intuition and knowledge of color theory.
            </Paragraph>
            <MKTypography variant="body2" component="div" pt={2}>
                The game is:
            </MKTypography>
            <Bullets>
                <li>Free</li>
                <li>No ads</li>
                <li>Open source</li>
                <li>Works offline</li>
                <li>Play in browser or install on mobile</li>
            </Bullets>
            <MKTypography variant="body2" component="div" pt={2}>
                See also detailed review on <ExternalLink href="https://freeappsforme.com/paint-match-app-review/">freeappsforme</ExternalLink>
            </MKTypography>
            <ItchIoBadge itchId={2050132} />
            <MobileStoreBadges
                appleLink="https://apps.apple.com/us/app/paint-match-offline/id6457674630?itsct=apps_box_badge&itscg=30200"
                androidLink="https://play.google.com/store/apps/details?id=com.ombosoft.paintmatch"
            />
            <GitHubBadge url="https://github.com/Ombosoft/paint-match" />
            <Paragraph pl={2} fontWeight="bold"><ExternalLink href="https://www.igdb.com/games/paint-match">Leave review on IGDB</ExternalLink></Paragraph>
            <MKTypography variant="h3" component="div" pt={2}>
                Credits
            </MKTypography>
            <Bullets>
                <li>
                    Game design and coding by <b>Shurick (Ombosoft)</b>
                </li>
                <li>
                    Music by{" "}
                    <b>
                        <ExternalLink href="https://kiwamialex.my.canva.site/">
                            Kiwami Alex
                        </ExternalLink>
                    </b>
                </li>
            </Bullets>
        </PortfolioPage>
    );
}
