import MKTypography from "../../material-kit/MKTypography";

export default function Paragraph({ children, ...rest }) {
    return (
        <MKTypography
            {...rest}
            variant="body2"
            component="div"
            textAlign="justify"
            py={2}
        >
            {children}
        </MKTypography>
    );
}
