import MKBox from "../material-kit/MKBox";

export default function ItchIoBadge({ itchId }) {
    const iframeUrl = `https://itch.io/embed/${itchId}?linkback=true&amp;border_width=2`;
    return (
        <MKBox p={1} pt={3}>
            <iframe
                style={{ border: "0px" }}
                src={iframeUrl}
                width="554"
                height="169"
                className="limitWidth"
            ></iframe>
        </MKBox>
    );
}
