import ReactGA from "react-ga4";

export function gaInit() {
    try {
        console.log("mode", import.meta.env.MODE);
        if (import.meta.env.DEV) {
            return;
        }
        ReactGA.initialize("G-YDD5M5NFPS");
    } catch {
        console.log("GA init failed");
    }
}

export function gaButton(buttonId) {
    gaAction("button", buttonId);
}

export function gaAction(actionType, actionId) {
    ReactGA.event("select_content", {
        content_type: actionType,
        content_id: actionId,
    });
}
