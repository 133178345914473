// eslint-disable-next-line import/no-unresolved
import screenshot from "src/images/SnakeVsBunnies/screenshot.webp";

import ItchIoBadge from "../../Components/ItchIoBadge";
import PortfolioPage from "../../Components/PortfolioPage";
import Bullets from "../../Components/Typography/Bullets";
import { SnakeVsBunniesRoute } from "../../Routes/MenuRoutes";
export default function SnakeVsBunnies() {
    return (
        <PortfolioPage
            title="Snake vs Bunnies"
            subtitle="Smooth locomotion snake game"
            description="Your food food run away and proliferate. Progress through levels with gradually increasing challenges."
            screenshot={screenshot}
            videoId="sK1hNi4dfqI?si=eluCfUkXaOBBLDu6"
            route={SnakeVsBunniesRoute}
        >
            <Bullets>
                <li>Free</li>
                <li>No ads</li>
                <li>Play in browser:</li>
            </Bullets>
            <ItchIoBadge itchId={1180349} />
        </PortfolioPage>
    );
}
