import MKTypography from "../../material-kit/MKTypography";

export default function Bullets({ children }) {
    return (
        <MKTypography variant="body2" component="div" pl={3}>
            <ul>
                {children}
            </ul>
        </MKTypography>
    );
}
