import MKBox from "../material-kit/MKBox";
import ExternalLink from "./ExternalLink";

export default function GitHubBadge({ url }) {
    return (
        <MKBox width="300px">
            <ExternalLink href={url}>
                <img
                    width="88%"
                    style={{
                        margin: "6%",
                        borderRadius: "10px",
                        borderColor: "gray",
                        borderWidth: "5px",
                        borderStyle: "solid",
                        border: "gray",
                    }}
                    alt="GitHub"
                    src="https://img.shields.io/badge/GitHub-100000?style=for-the-badge&logo=GitHub&logoColor=white&labelColor=black&color=black"
                />
            </ExternalLink>
        </MKBox>
    );
}
