/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unescaped-entities */
import design from "src/images/PaintMatch/design.webp";
import tiktok from "src/images/PaintMatch/tiktok.webp";
import timespent from "src/images/PaintMatch/timespent.webp";
import tutorial from "src/images/PaintMatch/tutorial.webp";
import BlogPage from "../../../Components/BlogPage";
import ExternalLink from "../../../Components/ExternalLink";
import InternalLink from "../../../Components/InternalLink";
import ResponsibleImage from "../../../Components/ResponsibleImage";
import Bullets from "../../../Components/Typography/Bullets";
import Paragraph from "../../../Components/Typography/Paragraph";
import SubHeader from "../../../Components/Typography/SubHeader";
import { PaintMatchPostMortemRoute } from "../../../Routes/MenuRoutes";

export default function PaintMatchPostMortem() {
    return (
        <BlogPage
            title="Paint Match Post Mortem"
            description="Learnings from building paint blending puzzle game Paint Match. What went well and what didn't go well."
            thumbnail={design}
            route={PaintMatchPostMortemRoute}
            date="2023-09-30"
        >
            <Paragraph>
                I released{" "}
                <InternalLink to="/games/PaintMatch" text="Paint Match" /> 5
                months ago and now it's a good time to reflect on how did it go.
            </Paragraph>
            <Paragraph>
                First and foremost, it was a hobby project. My goal was not to
                make money from it, even though people suggested I could. I made
                this game because I wanted to play it and there were no good
                paint mixing games around. It was also a learning experience,
                both in tech (I hadn't shipped anything major in React before)
                and in marketing.
            </Paragraph>
            <Paragraph>
                The game received 1K mobile installs and 10K browser plays in 5
                months. It got 30 total ratings, mostly 5 stars.
            </Paragraph>
            <SubHeader>What went well</SubHeader>
            <Bullets>
                <li>
                    Coding and game design
                    <Bullets>
                        <li>
                            I had lots of fun coding and implemented some cool
                            features, such as an efficient auto-solver for the
                            game and an auto-aim (yes, for a paint mixing puzzle
                            game)
                        </li>
                        <li>
                            I'm particularly proud of the tutorial I designed.
                            Primarily, it consists of a series of levels (more
                            than 50) with small hints here and there that
                            gradually introduce the player to the game mechanics
                            and help build their skills. After all, the game is
                            about training color intuition.
                        </li>
                        <ResponsibleImage src={tutorial} alt="tutorial" ml={-2} />
                    </Bullets>
                </li>
                <li>
                    Tools
                    <Bullets>
                        <li>
                            ReactJS was a good choice. Even though I wasted a
                            lot of time debugging some obscure browser issues,
                            many things were easy to do. Existing free libraries
                            like{" "}
                            <ExternalLink href="https://mui.com/material-ui/">
                                MUI
                            </ExternalLink>
                            ,{" "}
                            <ExternalLink href="https://nivo.rocks/">
                                nivo.rocks
                            </ExternalLink>
                            , and{" "}
                            <ExternalLink href="https://github.com/markusn/color-diff">
                                color-diff
                            </ExternalLink>{" "}
                            let me do pretty cool things relatively
                            effortlessly.
                        </li>
                        <li>
                            Packaging for mobile with{" "}
                            <ExternalLink href="https://capacitorjs.com/">
                                Ionic Capacitor
                            </ExternalLink>{" "}
                            was much easier than I anticipated.
                        </li>
                        <li>
                            <ExternalLink href="https://sentry.io/">
                                Sentry
                            </ExternalLink>{" "}
                            helped to catch and fix a few crashes that I hadn't
                            caught on my own. This is a truly amazing free tool
                            everyone should use (I'm not sponsored).
                        </li>
                    </Bullets>
                </li>
                <li>
                    People
                    <Bullets>
                        {" "}
                        <li>
                            Collaborating with a hobbyist composer was fun. It
                            gave me another portion of motivation to keep
                            working on the game. Teamwork is nice.
                        </li>
                        <li>
                            I contracted a few good designers and QAs on Fiverr
                            for a very reasonable price. That gave me both
                            (controversial) redesign idea and some early
                            constructive criticism.
                        </li>
                        <ResponsibleImage src={design} ml={-2} alt="design: before and after" />
                    </Bullets>
                </li>
                <li>
                    Marketing and engaging with players
                    <Bullets>
                        <li>
                            I'm very happy I shared the prototype early. The{" "}
                            <ExternalLink href="https://www.reddit.com/r/playmygame/comments/13bwnxz/while_learning_to_paint_i_became_fascinated_by/">
                                response
                            </ExternalLink>{" "}
                            was so positive and encouraging that it kept me
                            going for a while. Before that I had planned to wrap
                            up the prototype and move on, but instead I put a
                            ton of energy in polishing and making it look nice.
                        </li>
                        <li>
                            My{" "}
                            <ExternalLink href="https://www.tiktok.com/@ombosoft/video/7237968810089794842">
                                TikTok video
                            </ExternalLink>{" "}
                            went viral. <b>770K</b> views and <b>104K</b> likes
                            on a single clip! I was positively shocked and
                            couldn't believe my luck.
                            <ResponsibleImage src={tiktok} alt="tiktok screenshot"/>
                        </li>
                    </Bullets>
                </li>
            </Bullets>
            <SubHeader>What didn't go well</SubHeader>
            <Bullets>
                <li>
                    The redesign was not well received by a few original
                    prototype players. I saw some vague parallels with the
                    recent{" "}
                    <ExternalLink href="https://www.reddit.com/r/incremental_games/comments/15gy5uz/what_happened_to_dodecadragons/">
                        Dodeca Dragons story
                    </ExternalLink>
                    , so I chose to not take it to heart. Sorry guys if you're
                    reading this. I love you but I had to prioritize.
                </li>
                <li>
                    iOS browsers suck, especially on old devices, but even on
                    modern ones. Animations are laggy, SVG gradients are buggy,
                    and the list goes on. I ended up turning off a lot of juice
                    for this platform. Seriously, Apple?
                </li>
                <li>
                    I wasted some time on integrating Meta APIs to experiment
                    with targeting mobile installs in FB ads. These didn't work
                    for me. API docs are a mess, libraries are outdated, ads
                    manager tools are a usability nightmare. I've heard that
                    only major agencies truly understand how to optimize FB ads,
                    and that seems to be the case.
                </li>
                <li>
                    A few hobbyist collaborators promised to contribute, but
                    effectively ghosted me. It's hard to blame them though, the
                    only payment I offered was mention in credits anyway.
                </li>
                <li>
                    TikTok success wasn't reproducible, my subsequent clips gave
                    diminishing returns.{" "}
                </li>
            </Bullets>
            <Paragraph>
                Overall, I spent 262 hours on the game. This includes
                everything, from learning React to chatting with designers and
                responding to TikTok comments. If I had worked on the game
                full-time, I could've completed it in 5 weeks. That's
                significantly more than I estimated initially, but really, I
                loved every minute and learned a ton. Most importantly, I
                believe some people genuinely enjoyed my game, and that warms my
                heart.
            </Paragraph>
            <ResponsibleImage src={timespent} alt="time spent graph" />
        </BlogPage>
    );
}
