/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unescaped-entities */
import stat1 from "src/images/SnakeVsBunnies/stat1.webp";
import stat2 from "src/images/SnakeVsBunnies/stat2.webp";
import BlogPage from "../../../Components/BlogPage";
import ExternalLink from "../../../Components/ExternalLink";
import InternalLink from "../../../Components/InternalLink";
import ResponsibleImage from "../../../Components/ResponsibleImage";
import Bullets from "../../../Components/Typography/Bullets";
import Paragraph from "../../../Components/Typography/Paragraph";
import { SnakevsBunniesPostMortemRoute } from "../../../Routes/MenuRoutes";
import MKTypography from "../../../material-kit/MKTypography";

export default function SnakevsBunniesPostMortem() {
    return (
        <BlogPage
            title="Snake vs Bunnies Postmortem"
            description="My experience building Snake vs Bunnies game with Unity"
            thumbnail={stat1}
            route={SnakevsBunniesPostMortemRoute}
            date="2021-09-05"
        >
            <Paragraph>
                The <InternalLink to="/games/SnakeVsBunnies" text="game" />
                's got 7 players total in the first week. That's 7 more than I
                dreaded! This was a purely learning project, I've got useful
                skills playing with Unity and creative tools. These are
                transferable for new projects.
            </Paragraph>
            <ResponsibleImage src={stat1} alt="graph" />
            <MKTypography variant="h4" pt={2}>
                Unity
            </MKTypography>
            <Bullets>
                <li>
                    Unity is amazing. It overshadows all my previous attempts in
                    gamedev, including usage of LibGDX. So much of the essential
                    things are already done by Unity, all I needed was to focus
                    on the actual game, as opposed to boilerplate code or any
                    other sort of plumbing.
                </li>
                <li>
                    There’s <em>a lot</em> of tutorials and Q&amp;As about Unity
                    all over the internet and they helped me ramp up quickly. I
                    started with a free{" "}
                    <ExternalLink href="https://www.coursera.org/specializations/programming-unity-game-development">
                        Coursera Unity course
                    </ExternalLink>
                    . Even though it has a lot of content on programming which I
                    had to skip, it’s also a nice gentle introduction to the
                    engine.
                </li>
                <li>
                    Learning curve is not steep. It’s realy easy to do simple
                    things in Unity.
                </li>
                <li>
                    Unity is quite logical and well organized. Turns out it’s
                    easy to do intermediate things as well.
                </li>
            </Bullets>
            <MKTypography variant="h4" pt={2}>
                Assets
            </MKTypography>
            <Bullets>
                <li>
                    I’ve made all game assets except for music myself, and it
                    wasn’t that hard.
                </li>
                <li>
                    I’ve found a free soundtrack on{" "}
                    <ExternalLink href="https://freesound.org/">
                        freesound.org
                    </ExternalLink>
                    , the composer was fine with its usage.
                </li>
                <li>
                    Even though I can’t really paint, I was surprised by how
                    easy and satisfying it was to draw sprites using the right
                    tools (
                    <ExternalLink href="https://krita.org/">Krita</ExternalLink>{" "}
                    and a Wacom tablet). However, drawing took significant time,
                    comparable with coding. I had to cut the art scope short:
                    didn’t improve too much on the quality and didn’t do
                    animation. But if I had unlimited time, I could’ve done it.
                </li>
            </Bullets>
            <MKTypography variant="h4" pt={2}>
                Time management and scope
            </MKTypography>
            <Bullets>
                <li>
                    As usual, 20% of perceived work took 80% of actual time. But
                    without doing this 20% the game would’ve been utterly
                    unfinished and unplayable.
                </li>
                <li>
                    Being in this 20% is also less rewarding and I felt I’m
                    losing interest and thinking about next projects during this
                    final phase of development. This explains why there are so
                    many unfinished games laying around.
                </li>
            </Bullets>
            <MKTypography variant="h4" pt={2}>
                Marketing
            </MKTypography>
            <Bullets>
                <li>
                    I didn’t spend any money on marketing. All I did was a tweet
                    and a couple Reddit posts.
                </li>
                <li>
                    I’ve invested some time in a trailer video that, in my view
                    explains the essence of the game well enough.
                </li>
                <li>
                    If the itch.io statistics is trustable, my SMM did pretty
                    much nothing. Vast majority of visitors came directly from
                    the platform. But also numbers are so small they may not
                    representative.
                </li>
                <ResponsibleImage src={stat2} alt="graph" />
            </Bullets>
            <Paragraph>
                It seems like a casual game has to be mobile first these days.
                Mine was desktop only and that likely lost some audience.
                Anyway, check out{" "}
                <InternalLink
                    to="/games/SnakeVsBunnies"
                    text="Snake vs Bunnies"
                />{" "}
                in my portfolio.
            </Paragraph>
        </BlogPage>
    );
}
