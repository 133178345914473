import MKBox from "../../material-kit/MKBox";

import { Box, Stack } from "@mui/material";
import { MenuRoutes } from "../../Routes/MenuRoutes";
import OmbosoftIcon from "../OmbosoftIcon";
import DefaultNavbar from "./DefaultNavbar";

export function NavBar() {
    return (
        <nav>
            <MKBox variant="gradient" bgColor="dark" shadow="sm" py={0.25}>
                <DefaultNavbar
                    brand={
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <OmbosoftIcon />
                            <Box>Ombosoft</Box>
                        </Stack>
                    }
                    routes={MenuRoutes}
                    transparent
                    relative
                    light
                />
            </MKBox>
        </nav>
    );
}
