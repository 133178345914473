import HomePage from "../Pages/HomePage";
import { PrivacyPage } from "../Pages/PrivacyPage";
import { ToSPage } from "../Pages/ToSPage";

export const PrivacyRoute =     {
    path: "/privacy-policy",
    element: <PrivacyPage/>,
};

export const ToSRoute =     {
    path: "/terms-of-service",
    element: <ToSPage/>,
};

export const HiddenRoutes = [
    {
        path: "/",
        element: <HomePage/>,
    },
    PrivacyRoute,
    ToSRoute,
];
