import MKBox from "../material-kit/MKBox";
import ExternalLink from "./ExternalLink";

export default function MobileStoreBadges({ appleLink, androidLink }) {
    return (
        <MKBox>
            {appleLink && (
                <MKBox width="300px">
                    <ExternalLink href={appleLink}>
                        <img
                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1691625600"
                            width="88%"
                            style={{ margin: "6%" }}
                        />
                    </ExternalLink>
                </MKBox>
            )}
            {androidLink && (
                <MKBox width="300px">
                    <ExternalLink href={androidLink}>
                        <img
                            width="100%"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                        />
                    </ExternalLink>
                </MKBox>
            )}
        </MKBox>
    );
}
