/* eslint-disable react/no-unescaped-entities */
import OpenGraph from "../Components/OpenGraph";
import PageBox from "../Components/PageBox";
import Paragraph from "../Components/Typography/Paragraph";
import SubHeader from "../Components/Typography/SubHeader";
import { ToSRoute } from "../Routes/HiddenRoutes";
import { formatDateToLocale } from "../Util/DateFormatter";
import MKTypography from "../material-kit/MKTypography";
// eslint-disable-next-line import/no-unresolved
import logoUrl from "src/images/ombosoft.png";
import { emailAddress } from "./AboutPage";

export function ToSPage() {
    const modifiedDate = "2023-10-03";
    return (
        <>
            <OpenGraph
                title="ombosoft.com privacy policy"
                thumbnail={logoUrl}
                route={ToSRoute}
                date={modifiedDate}
            />
            <PageBox>
                <MKTypography variant="h1" color="info" textGradient>
                    ombosoft.com Terms of Service
                </MKTypography>
                <MKTypography variant="body2" color="secondary">
                    <time dateTime={modifiedDate}>
                        Last Updated: {formatDateToLocale(modifiedDate)}
                    </time>
                </MKTypography>

                <Paragraph>
                    Thank you for visiting Ombosoft.com! The following Terms of
                    Service ("Terms") govern your access to and use of the
                    Ombosoft website, including any content and services offered
                    therein. By accessing or using the website, you agree to be
                    bound by these Terms.
                </Paragraph>

                <SubHeader>1. Use of the Website</SubHeader>
                <Paragraph>
                    You are granted a limited, non-exclusive, revocable license
                    to access and use the website for its intended purpose:
                    viewing and obtaining information provided on the site.
                </Paragraph>

                <SubHeader>2. Third-Party Links</SubHeader>
                <Paragraph>
                    Our website contains links to third-party websites or
                    services, such as the Play Store, itch.io, etc., which are
                    not owned or controlled by Ombosoft. Ombosoft has no control
                    over, and assumes no responsibility for, the content,
                    privacy policies, or practices of any third-party websites
                    or services. By using our website, you acknowledge and agree
                    that Ombosoft will not be responsible or liable, directly or
                    indirectly, for any damage or loss caused or alleged to be
                    caused by or in connection with the use of or reliance on
                    any such content, goods, or services available on or through
                    any such third-party websites or services.
                </Paragraph>

                <SubHeader>3. Intellectual Property</SubHeader>
                <Paragraph>
                    All content on this website, including but not limited to
                    text, images, logos, and graphics, is the property of
                    Ombosoft or its respective owners and is protected by
                    copyright laws. Unauthorized use may violate copyright,
                    trademark, and other laws. Please contact {emailAddress} if
                    you want to use information from the site.
                </Paragraph>

                <SubHeader>4. Limitation of Liability</SubHeader>
                <Paragraph>
                    Ombosoft, its affiliates, agents, directors, employees, and
                    suppliers will not be liable for any indirect, special,
                    incidental, or consequential damages, or any loss of
                    revenue, profits, or data arising in connection with the use
                    or inability to use the website.
                </Paragraph>

                <SubHeader>5. Changes to the Terms of Service</SubHeader>
                <Paragraph>
                    Ombosoft reserves the right to modify or replace these Terms
                    at any time. If we make changes, we will notify you by
                    revising the "Last Updated" date at the top of this page.
                </Paragraph>

                <SubHeader>6. Governing Law</SubHeader>
                <Paragraph>
                    These Terms are governed by and construed in accordance with
                    the laws of the jurisdiction where Ombosoft operates,
                    without regard to its conflict of law provisions.
                </Paragraph>

                <SubHeader>7. Contact Us</SubHeader>
                <Paragraph>
                    For any questions or concerns regarding these Terms of
                    Service, please contact us at {emailAddress}.
                </Paragraph>
            </PageBox>
        </>
    );
}
