import { Navigate } from "react-router-dom";

export const Redirects = [
    {
        path: "/snake-vs-bunnies-postmortem",
        element: <Navigate to="/blog/SnakevsBunniesPostMortem" />,
    },
    {
        path: "/snake-vs-bunnies",
        element: <Navigate to="/blog/SnakevsBunniesRelease" />,
    },
    {
        path: "/big-lap-stopwatch",
        element: <Navigate to="/blog/BlogBigLapStopwatchRelease" />,
    },
    {
        path: "/tags/ombosoft",
        element: <Navigate to="/" />,
    },
    {
        path: "/tags/game",
        element: <Navigate to="/" />,
    },
    {
        path: "/tags/stopwatch",
        element: <Navigate to="/apps/BigLapStopwatch" />,
    },
    {
        path: "/tags/snake",
        element: <Navigate to="/games/SnakeVsBunnies" />,
    },
    {
        path: "/tags/postmortem",
        element: <Navigate to="/blog/PaintMatchPostMortem" />,
    },
];
