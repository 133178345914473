/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unescaped-entities */
import screenshot from "src/images/SnakeVsBunnies/screenshot.webp";
import BlogPage from "../../../Components/BlogPage";
import InternalLink from "../../../Components/InternalLink";
import Paragraph from "../../../Components/Typography/Paragraph";
import YoutubeEmbed from "../../../Components/YoutubeEmbed";
import { SnakevsBunniesReleaseRoute } from "../../../Routes/MenuRoutes";

export default function SnakevsBunniesRelease() {
    return (
        <BlogPage
            title="Snake vs Bunnies released"
            description="A snake game with smooth locomotion is released, play for free on Itch.io"
            thumbnail={screenshot}
            route={SnakevsBunniesReleaseRoute}
            date="2021-08-31"
        >
            <Paragraph>
                This is a tribute to an arcade game I played as a kid. Snake vs
                Bunnies is a snake game with a twist. The food run away from you
                and proliferate. You control the smoothly slithering snake, eat
                bunnies. Once you eat all bunnies on a level, you go to next
                one, and all levels are procedurally generated, making game
                gradually more challenging.
            </Paragraph>
            <Paragraph>
                Bunnies will try to escape their fate if they see you, faster
                and faster with level progression. If they don’t see you and
                once they ate their carrots, they will make more bunnies, very
                quickly.
            </Paragraph>
            <YoutubeEmbed videoId="sK1hNi4dfqI?si=rMccWH_Y5oQfuOpr" />
            <Paragraph>
                Check out{" "}
                <InternalLink
                    to="/games/SnakeVsBunnies"
                    text="Snake vs Bunnies"
                />{" "}
                in my portfolio.
            </Paragraph>
        </BlogPage>
    );
}
