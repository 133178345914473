import { Divider } from "@mui/material";
import { formatDateToLocale } from "../Util/DateFormatter";
import MKTypography from "../material-kit/MKTypography";
import InternalLink from "./InternalLink";
import OmbosoftIcon from "./OmbosoftIcon";
import OpenGraph from "./OpenGraph";
import PageBox from "./PageBox";

export default function BlogPage({
    date,
    title,
    description,
    thumbnail,
    route,
    children,
}) {
    return (
        <>
            <OpenGraph
                date={date}
                title={title}
                description={description}
                thumbnail={thumbnail}
                route={route}
                type="article"
            />
            <PageBox>
                <article>
                    <header>
                        <MKTypography variant="h1" color="info" textGradient>
                            {title}
                        </MKTypography>
                        <MKTypography variant="body2" color="secondary">
                            <time dateTime={date}>
                                {formatDateToLocale(date)}
                            </time>
                        </MKTypography>
                    </header>
                    <section>{children}</section>
                    <footer>
                        <Divider />
                        <MKTypography variant="body2" color="secondary">
                            Written by <OmbosoftIcon round />{" "}
                            <InternalLink
                                to="/about"
                                text="Shurick"
                                color="secondary"
                            />
                        </MKTypography>
                    </footer>
                </article>
            </PageBox>
        </>
    );
}
