// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Collapse from "@mui/material/Collapse";

// Material Kit 2 React components
import MKBox from "../..//material-kit/MKBox";
import MKTypography from "../../material-kit/MKTypography";

function DefaultNavbarDropdown({
    name,
    icon,
    children,
    collapseStatus,
    light,
    href,
    route,
    onMenuItemClicked,
    ...rest
}) {
    const linkComponent = {
        component: "a",
        href,
        target: "_blank",
        rel: "noreferrer",
    };

    const routeComponent = {
        component: Link,
        to: route,
        onClick: () => {
            onMenuItemClicked();
        },
    };

    return (
        <>
            <MKBox
                id="routeComponent"
                {...rest}
                mx={1}
                p={1}
                display="flex"
                alignItems="baseline"
                color={light ? "white" : "dark"}
                opacity={light ? 1 : 0.6}
                sx={{ cursor: "pointer", userSelect: "none" }}
                {...(route && routeComponent)}
                {...(href && linkComponent)}
            >
                {/* header */}
                <MKTypography
                    variant="body2"
                    lineHeight={1}
                    color="inherit"
                    sx={{
                        alignSelf: "center",
                        "& *": { verticalAlign: "middle" },
                    }}
                >
                    {icon}
                </MKTypography>
                <MKTypography
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    color={light ? "white" : "dark"}
                    sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
                >
                    {name}
                </MKTypography>
            </MKBox>
            {children && (
                <Collapse
                    in={Boolean(collapseStatus)}
                    timeout={400}
                    unmountOnExit
                >
                    {children}
                </Collapse>
            )}
        </>
    );
}

// Setting default values for the props of DefaultNavbarDropdown
DefaultNavbarDropdown.defaultProps = {
    children: false,
    collapseStatus: false,
    light: false,
    href: "",
    route: "",
};

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    children: PropTypes.node,
    collapseStatus: PropTypes.bool,
    light: PropTypes.bool,
    href: PropTypes.string,
    route: PropTypes.string,
    onMenuItemClicked: PropTypes.func,
};

export default DefaultNavbarDropdown;
