import { Helmet } from "react-helmet-async";
import { rootUrl } from "../Util/CanonicalLink";

export default function OpenGraph({
    date,
    title,
    description,
    thumbnail,
    route,
    type,
}) {
    const imgUrl = thumbnail ? new URL(thumbnail, rootUrl).href : null;
    const pageUrl = route ? new URL(route.route ?? route.path, rootUrl).href : null;
    const ogType = type ? type : "website";
    return (
        <Helmet>
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}
            <meta name="author" content="Shurick" />

            {title && <meta property="og:title" content={title} />}
            {description && (
                <meta property="og:description" content={description} />
            )}
            {thumbnail && imgUrl && (
                <meta property="og:image" content={imgUrl} />
            )}
            <meta property="og:type" content={ogType} />
            {route && pageUrl && <meta property="og:url" content={pageUrl} />}
            {date && (
                <meta property="og:article:published_time" content={date} />
            )}

            {title && <meta name="twitter:title" content={title} />}
            {description && (
                <meta name="twitter:description" content={description} />
            )}
            {thumbnail && imgUrl && (
                <meta name="twitter:image" content={imgUrl} />
            )}
        </Helmet>
    );
}
