import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { PrivacyRoute, ToSRoute } from "../Routes/HiddenRoutes";
import MKBox from "../material-kit/MKBox";

export function Footer() {
    return (
        <footer>
            <MKBox
                mt={8}
                p={2}
                width="100%"
                textAlign="center"
                style={{ backgroundColor: "#f7f7f7" }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box />
                    <Stack direction="row" spacing={2}>
                        <Box
                            component={Link}
                            to={PrivacyRoute.path}
                            sx={{ fontSize: "0.9rem" }}
                        >
                            Privacy
                        </Box>
                        <Box
                            component={Link}
                            to={ToSRoute.path}
                            sx={{ fontSize: "0.9rem" }}
                        >
                            Terms
                        </Box>
                    </Stack>
                    <Box sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                        © 2023-2024 Ombosoft
                    </Box>
                </Stack>
            </MKBox>
        </footer>
    );
}
