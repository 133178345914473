export default function YoutubeEmbed({ videoId }) {
    const src = `https://www.youtube.com/embed/${videoId}`;
    return (
        <iframe
            width="560"
            height="315"
            src={src}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen={true}
            className="limitWidth"
            style={{ border: "0px"}}
        ></iframe>
    );
}
