export default function ResponsibleImage({ src, ml, para, ...rest }) {
    const mLeft = ml ? `${ml}em` : '0px';
    return para ?
        (<p>
            <img
                src={src}
                className="limitWidth"
                {...rest}
                style={{ marginLeft: mLeft }}
            />
        </p>
        ) : (

            <img
                src={src}
                className="limitWidth"
                {...rest}
                style={{ marginLeft: mLeft }}
            />
        );
}
