import { Link } from "react-router-dom";
import MKBox from "../material-kit/MKBox";
import MKTypography from "../material-kit/MKTypography";
import InternalLink from "./InternalLink";
import OpenGraph from "./OpenGraph";
import PageBox from "./PageBox";
import ResponsibleImage from "./ResponsibleImage";
import YoutubeEmbed from "./YoutubeEmbed";

export default function PortfolioPage({
    title,
    subtitle,
    description,
    screenshot,
    videoId,
    route,
    headerOnly,
    children,
}) {
    console.log(route);
    return (
        <>
            {!headerOnly && (
                <OpenGraph
                    title={title}
                    description={description}
                    thumbnail={screenshot}
                    route={route}
                />
            )}
            <PageBox>
                <MKTypography variant="h1" color="info" textGradient>
                    {headerOnly ? <Link to={route.route}>{title}</Link> : title}
                </MKTypography>
                <MKTypography variant="h3">{subtitle}</MKTypography>
                <MKTypography variant="body2">{description}</MKTypography>
                <MKBox p={1}>
                    <ResponsibleImage src={screenshot} alt="screenshot" />
                </MKBox>
                <MKBox p={1} heigh="fitContent">
                    <YoutubeEmbed videoId={videoId} />
                </MKBox>
                {headerOnly ? (
                    <InternalLink to={route.route} text="Read more" />
                ) : (
                    children
                )}
            </PageBox>
        </>
    );
}
