import { Stack } from "@mui/material";
import MKBox from "../material-kit/MKBox";

export default function Social({ icon, network, link, handle }) {
    return (
        <Stack
            direction="row"
            spacing={1}
            display="flex"
            alignContent="center"
            alignItems="center"
        >
            {icon}
            <MKBox>{network}: </MKBox>
            <a href={link}>{handle}</a>
        </Stack>
    );
}
