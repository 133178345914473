// eslint-disable-next-line import/no-unresolved
import screenshot from "src/images/BigLapStopwatch/phone.webp";

import ExternalLink from "../../Components/ExternalLink";
import GitHubBadge from "../../Components/GitHubBadge";
import MobileStoreBadges from "../../Components/MobileStoreBadges";
import PortfolioPage from "../../Components/PortfolioPage";
import Bullets from "../../Components/Typography/Bullets";
import { BigLapStopwatchRoute } from "../../Routes/MenuRoutes";
import MKTypography from "../../material-kit/MKTypography";
export default function BigLapStopwatch() {
    return (
        <PortfolioPage
            title="Big Lap Stopwatch"
            subtitle="Minimalist Stopwatch Android App"
            description="Instant and straightforward high visibility stopwatch."
            screenshot={screenshot}
            videoId="Mf8v16ttEF4?si=_9eoo1HFE0v9rjbz"
            route={BigLapStopwatchRoute}
        >
            <Bullets>
                <li>Free</li>
                <li>Large clear fonts</li>
                <li>No unnecessary design elements</li>
                <li>No settings</li>
                <li>No screen saver</li>
                <li>No milliseconds</li>
                <li>No ads</li>
            </Bullets>
            <MKTypography variant="body2" component="div" pt={2}>
                See also detailed review on <ExternalLink href="https://androidappsforme.com/big-lap-stopwatch-app-review/">Androidappsforme</ExternalLink>
            </MKTypography>
            <MobileStoreBadges androidLink="https://play.google.com/store/apps/details?id=top.ombosoft.biglapstopwatch&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" />
            <GitHubBadge url="https://github.com/Ombosoft/biglapstopwatch" />
        </PortfolioPage>
    );
}
