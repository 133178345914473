import * as Sentry from "@sentry/react";

export default function sentryInit() {
    try {
        if (import.meta.env.DEV) {
            return;
        }
        Sentry.init({
            dsn: "https://3115f0a44c06b3fba12bc5390761a04b@o4505380972265472.ingest.sentry.io/4505895222968320",
            integrations: [
                new Sentry.Replay({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            // Performance Monitoring
            tracesSampleRate: 1, // Capture 100% of the transactions, reduce in production!
            // Session Replay
            replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
        console.log("Sentry initialized");
    } catch {
        console.log("Sentry init failed");
    }
}
