/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unescaped-entities */
import st from "src/images/WASD2024/StarshipTroopers.jpeg";
import wasdLogo from "src/images/WASD2024/WASD.png";
import bs from "src/images/WASD2024/bs.png";
import eow from "src/images/WASD2024/eow.jpg";
import microtopia from "src/images/WASD2024/microtopia.jpg";
import rp7 from "src/images/WASD2024/rp7.jpg";
import tdp from "src/images/WASD2024/the-deadly-path.webp";
import tm from "src/images/WASD2024/tm.webp";
import BlogPage from "../../../Components/BlogPage";
import ExternalLink from "../../../Components/ExternalLink";
import ResponsibleImage from "../../../Components/ResponsibleImage";
import Paragraph from "../../../Components/Typography/Paragraph";
import { WASD2024Route } from "../../../Routes/MenuRoutes";
import MKTypography from "../../../material-kit/MKTypography";

export default function WASD2024() {
    return (
        <BlogPage
            title="WASD 2024: Most intriguing games"
            description="My personal opinion from visiting the conference"
            thumbnail={wasdLogo}
            route={WASD2024Route}
            date="2024-04-27"
        >
            <Paragraph>
                <ResponsibleImage src={wasdLogo} alt="WASD" para />
                I attended the WASD conference in London. I spent almost the whole day there.
                I played lots of games and met amazing developers from around the world. I want to share things what impressed me the most.
            </Paragraph>
            <Paragraph>
                <MKTypography variant="h3" component="div" pt={2}>
                    <ExternalLink href="https://store.steampowered.com/app/2750000/Microtopia/">Microtopia</ExternalLink>
                </MKTypography>
                <ResponsibleImage src={microtopia} para alt="Microtopia" />
                A really nice looking ant colony automation game. It’s unique both from technical art (everything looks like it’s under electron microscope) and gameplay perspectives. There’s ants with limited lifespans instead of conveyors, so you need to manage the population and distribution of ants as well as resources.

            </Paragraph>
            <Paragraph>
                <MKTypography variant="h3" component="div" pt={2}>
                    The Deadly Path
                </MKTypography>
                <ResponsibleImage src={tdp} para alt="The Deadly Path" />
                Surprisingly good “digging” / resource manager / card game. It’s not released yet but I’m really looking forward to playing this one more. 
                It somehow combines the vibes of Dungeon Keeper and Frostpunk for me, while looking like Mahjong from a distance. Wild!
            </Paragraph>
            <Paragraph>
                <MKTypography variant="h3" component="div" pt={2}>
                    <ExternalLink href="https://store.steampowered.com/app/1334500/RP7/">RP7</ExternalLink>
                </MKTypography>
                <ResponsibleImage src={rp7} para alt="RP7" />
                This Korean indie game combines slot machines mechanics with “D&D”, I think in a humorous and unusual way.
                It’s really well polished and looks like candy. The UI reminds me of mobile games and evokes expectations of microtransactions and ads, but it's a false
                sense (I hope). I was also very impressed by super responsive and tactile custom controller they brought to the show.
            </Paragraph>
            <Paragraph>
                <MKTypography variant="h3" component="div" pt={2}>
                    <ExternalLink href="https://store.steampowered.com/app/1202130/Starship_Troopers_Terran_Command/">Starship Troopers</ExternalLink>
                </MKTypography>
                <ResponsibleImage src={st} para alt="Starship Troopers" />

                I thought the RTS genre is mostly dead now, but oh boy I was wrong! The game is visually appealing and has a very satisfying game feel. These bullets!
                Controls are easy and there's not much micro management, at least at the levels I played. They've implemented nice automatic formations and other QoL features that
                make you feel good.
            </Paragraph>
            <Paragraph>
                <MKTypography variant="h3" component="div" pt={2}>
                    <ExternalLink href="https://store.steampowered.com/app/2524780/Echo_of_the_Waves/">Echo of the waves</ExternalLink>
                </MKTypography>
                <ResponsibleImage src={eow} para alt="Echo of the waves" />
                A very relaxing cozy game with beautiful hand drawn art.
                It looks like it was painted with pastels or watercolors or something of this sort.
                Not much gameplay per se, but really pleasing to look at while playing.
            </Paragraph>
            <Paragraph>
                <MKTypography variant="h3" component="div" pt={2}>
                    Turnip Mountain
                </MKTypography>
                <ResponsibleImage src={tm} para alt="Turnip Mountain" />
                A skill based dual stick climbing game. As a climber myself, I couldn’t walk past this one. 
                I love it when there's an actual skill progression in the game instead of virtual stats boosting. 
                Previous game I enjoyed in this category was In Death Unchained.
            </Paragraph>
            <Paragraph>
                <MKTypography variant="h3" component="div" pt={2}>
                    <ExternalLink href="https://www.teamjunkfish.com/games/bootleg-steamer">Bootleg Steamer</ExternalLink>
                </MKTypography>
                <ResponsibleImage src={bs} para alt="Bootleg Steamer" />
                It's a nostalgic game featuring Elite-like goods trading. I had fun bumping into other ships and leading them astray.
                The art style is very cozy and it was generally pleasant to just sail around.
            </Paragraph>
        </BlogPage>
    );
}
