import { HiddenRoutes } from "./HiddenRoutes";
import { MenuRoutes } from "./MenuRoutes";
import { Redirects } from "./Redirects";

export const walkRoutes = (routes) =>
    routes.flatMap((route) => {
        if (route.collapse) {
            return walkRoutes(route.collapse);
        }

        if (route.route) {
            return {
                path: route.route,
                element: route.component,
            };
        }

        return [];
    });

export const AllRoutes = walkRoutes(MenuRoutes)
    .concat(Redirects)
    .concat(HiddenRoutes);
