import { Link } from "react-router-dom";
import MKTypography from "../material-kit/MKTypography";

export default function InternalLink({ to, text, color }) {
    return (
        <MKTypography
            component={Link}
            to={to}
            variant="body2"
            color={color ?? "info"}
            fontWeight="bold"
        >
            {text}
        </MKTypography>
    );
}
