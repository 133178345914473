/* eslint-disable no-param-reassign */
import { Fragment, useEffect, useState } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Close, Menu } from "@mui/icons-material";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";
import Popper from "@mui/material/Popper";

import { Box } from "@mui/material";
// eslint-disable-next-line import/no-unresolved
import breakpoints from "assets/theme/base/breakpoints";
import MKBox from "../../material-kit/MKBox";
import MKTypography from "../../material-kit/MKTypography";
import DefaultNavbarDropdown from "./DefaultNavbarDropdown";
import DefaultNavbarMobile from "./DefaultNavbarMobile";

function DefaultNavbar({
    brand,
    routes,
    transparent,
    light,
    sticky,
    relative,
}) {
    const [dropdown, setDropdown] = useState("");
    const [dropdownEl, setDropdownEl] = useState("");
    const [dropdownName, setDropdownName] = useState("");
    const [nestedDropdown, setNestedDropdown] = useState("");
    const [nestedDropdownEl, setNestedDropdownEl] = useState("");
    const [nestedDropdownName, setNestedDropdownName] = useState("");
    const [mobileNavbar, setMobileNavbar] = useState(false);
    const [mobileView, setMobileView] = useState(false);

    const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);
    const menuItemClicked = () => {
        if (mobileView) {
            openMobileNavbar();
        } else {
            setDropdown(null);
        }
    };
    useEffect(() => {
        // A function that sets the display state for the DefaultNavbarMobile.
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
                setMobileNavbar(false);
            } else {
                setMobileView(false);
                setMobileNavbar(false);
            }
        }

        /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
        window.addEventListener("resize", displayMobileNavbar);

        // Call the displayMobileNavbar function to set the state with the initial value.
        displayMobileNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    const renderNavbarItems = routes.map(
        ({ name, icon, href, route, collapse, spacer }) =>
            spacer ? (
                <Spacer key={1} />
            ) : (
                <DefaultNavbarDropdown
                    key={name}
                    name={name}
                    icon={icon}
                    href={href}
                    route={route}
                    onMouseEnter={({ currentTarget }) => {
                        if (collapse) {
                            setDropdown(currentTarget);
                            setDropdownEl(currentTarget);
                            setDropdownName(name);
                        }
                    }}
                    onMouseLeave={() => collapse && setDropdown(null)}
                    light={light}
                    onMenuItemClicked={() => {
                        menuItemClicked();
                    }}
                />
            )
    );

    // Render the routes on the dropdown menu
    const renderRoutes = routes.map(
        ({ name, collapse, columns, rowsPerColumn }) => {
            let template;

            // Render the dropdown menu that should be display as columns
            if (collapse && columns && name === dropdownName) {
                const calculateColumns = collapse.reduce(
                    (resultArray, item, index) => {
                        const chunkIndex = Math.floor(index / rowsPerColumn);

                        if (!resultArray[chunkIndex]) {
                            resultArray[chunkIndex] = [];
                        }

                        resultArray[chunkIndex].push(item);

                        return resultArray;
                    },
                    []
                );

                template = (
                    <Grid key={name} container spacing={3} py={1} px={1.5}>
                        {calculateColumns.map((cols, key) => {
                            const gridKey = `grid-${key}`;
                            const dividerKey = `divider-${key}`;

                            return (
                                <Grid
                                    key={gridKey}
                                    item
                                    xs={12 / columns}
                                    sx={{ position: "relative" }}
                                >
                                    {cols.map((col, index) => (
                                        <Fragment key={col.name}>
                                            <MKTypography
                                                display="block"
                                                variant="button"
                                                fontWeight="bold"
                                                textTransform="capitalize"
                                                py={1}
                                                px={0.5}
                                                mt={index !== 0 ? 2 : 0}
                                            >
                                                {col.name}
                                            </MKTypography>
                                            {col.collapse.map((item) => (
                                                <MKTypography
                                                    key={item.name}
                                                    component={
                                                        item.route
                                                            ? Link
                                                            : MuiLink
                                                    }
                                                    to={
                                                        item.route
                                                            ? item.route
                                                            : ""
                                                    }
                                                    href={
                                                        item.href
                                                            ? item.href
                                                            : (e) =>
                                                                  e.preventDefault()
                                                    }
                                                    target={
                                                        item.href
                                                            ? "_blank"
                                                            : ""
                                                    }
                                                    rel={
                                                        item.href
                                                            ? "noreferrer"
                                                            : "noreferrer"
                                                    }
                                                    minWidth="11.25rem"
                                                    display="block"
                                                    variant="button"
                                                    color="text"
                                                    textTransform="capitalize"
                                                    fontWeight="regular"
                                                    py={0.625}
                                                    px={2}
                                                    sx={({
                                                        palette: { grey, dark },
                                                        borders: {
                                                            borderRadius,
                                                        },
                                                    }) => ({
                                                        borderRadius:
                                                            borderRadius.md,
                                                        cursor: "pointer",
                                                        transition:
                                                            "all 300ms linear",

                                                        "&:hover": {
                                                            backgroundColor:
                                                                grey[200],
                                                            color: dark.main,
                                                        },
                                                    })}
                                                    onClick={menuItemClicked}
                                                >
                                                    {item.name}
                                                </MKTypography>
                                            ))}
                                        </Fragment>
                                    ))}
                                    {key !== 0 && (
                                        <Divider
                                            key={dividerKey}
                                            orientation="vertical"
                                            sx={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "-4px",
                                                transform: "translateY(-45%)",
                                                height: "90%",
                                            }}
                                        />
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                );

                // Render the dropdown menu that should be display as list items
            } else if (collapse && name === dropdownName) {
                template = collapse.map((item) => {
                    const linkComponent = {
                        component: MuiLink,
                        href: item.href,
                        target: "_blank",
                        rel: "noreferrer",
                    };

                    const routeComponent = {
                        component: Link,
                        to: item.route,
                    };

                    return (
                        <MKTypography
                            key={item.name}
                            {...(item.route ? routeComponent : linkComponent)}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            variant="button"
                            textTransform="capitalize"
                            minWidth={item.description ? "14rem" : "12rem"}
                            color={item.description ? "dark" : "text"}
                            fontWeight={item.description ? "bold" : "regular"}
                            py={item.description ? 1 : 0.625}
                            px={2}
                            sx={({
                                palette: { grey, dark },
                                borders: { borderRadius },
                            }) => ({
                                borderRadius: borderRadius.md,
                                cursor: "pointer",
                                transition: "all 300ms linear",

                                "&:hover": {
                                    backgroundColor: grey[200],
                                    color: dark.main,

                                    "& *": {
                                        color: dark.main,
                                    },
                                },
                            })}
                            onMouseEnter={({ currentTarget }) => {
                                if (item.dropdown) {
                                    setNestedDropdown(currentTarget);
                                    setNestedDropdownEl(currentTarget);
                                    setNestedDropdownName(item.name);
                                }
                            }}
                            onMouseLeave={() => {
                                if (item.dropdown) {
                                    setNestedDropdown(null);
                                }
                            }}
                            onClick={menuItemClicked}
                        >
                            {item.description ? (
                                <MKBox>
                                    {item.name}
                                    <MKTypography
                                        display="block"
                                        variant="button"
                                        color="text"
                                        fontWeight="regular"
                                        sx={{ transition: "all 300ms linear" }}
                                    >
                                        {item.description}
                                    </MKTypography>
                                </MKBox>
                            ) : (
                                item.name
                            )}
                            {item.collapse && (
                                <Icon
                                    fontSize="small"
                                    sx={{
                                        fontWeight: "normal",
                                        verticalAlign: "middle",
                                        mr: -0.5,
                                    }}
                                >
                                    keyboard_arrow_right
                                </Icon>
                            )}
                        </MKTypography>
                    );
                });
            }

            return template;
        }
    );

    // Routes dropdown menu
    const dropdownMenu = (
        <Popper
            anchorEl={dropdown}
            popperRef={null}
            open={Boolean(dropdown)}
            placement="top-start"
            transition
            style={{ zIndex: 10 }}
            modifiers={[
                {
                    name: "arrow",
                    enabled: true,
                    options: {},
                },
            ]}
            onMouseEnter={() => setDropdown(dropdownEl)}
            onMouseLeave={() => {
                if (!nestedDropdown) {
                    setDropdown(null);
                    setDropdownName("");
                }
            }}
        >
            {({ TransitionProps }) => (
                <Grow
                    {...TransitionProps}
                    sx={{
                        transformOrigin: "left top",
                        background: ({ palette: { white } }) => white.main,
                    }}
                >
                    <MKBox borderRadius="lg">
                        <MKBox shadow="lg" borderRadius="lg" p={2} mt={2}>
                            {renderRoutes}
                        </MKBox>
                    </MKBox>
                </Grow>
            )}
        </Popper>
    );

    // Render routes that are nested inside the dropdown menu routes
    const renderNestedRoutes = routes.map(({ collapse, columns }) =>
        collapse && !columns
            ? collapse.map(({ name: parentName, collapse: nestedCollapse }) => {
                  let template;

                  if (parentName === nestedDropdownName) {
                      template =
                          nestedCollapse &&
                          nestedCollapse.map((item) => {
                              const linkComponent = {
                                  component: MuiLink,
                                  href: item.href,
                                  target: "_blank",
                                  rel: "noreferrer",
                              };

                              const routeComponent = {
                                  component: Link,
                                  to: item.route,
                              };

                              return (
                                  <MKTypography
                                      key={item.name}
                                      {...(item.route
                                          ? routeComponent
                                          : linkComponent)}
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      variant="button"
                                      textTransform="capitalize"
                                      minWidth={
                                          item.description ? "14rem" : "12rem"
                                      }
                                      color={item.description ? "dark" : "text"}
                                      fontWeight={
                                          item.description ? "bold" : "regular"
                                      }
                                      py={item.description ? 1 : 0.625}
                                      px={2}
                                      sx={({
                                          palette: { grey, dark },
                                          borders: { borderRadius },
                                      }) => ({
                                          borderRadius: borderRadius.md,
                                          cursor: "pointer",
                                          transition: "all 300ms linear",

                                          "&:hover": {
                                              backgroundColor: grey[200],
                                              color: dark.main,

                                              "& *": {
                                                  color: dark.main,
                                              },
                                          },
                                      })}
                                  >
                                      {item.description ? (
                                          <MKBox>
                                              {item.name}
                                              <MKTypography
                                                  display="block"
                                                  variant="button"
                                                  color="text"
                                                  fontWeight="regular"
                                                  sx={{
                                                      transition:
                                                          "all 300ms linear",
                                                  }}
                                              >
                                                  {item.description}
                                              </MKTypography>
                                          </MKBox>
                                      ) : (
                                          item.name
                                      )}
                                      {item.collapse && (
                                          <Icon
                                              fontSize="small"
                                              sx={{
                                                  fontWeight: "normal",
                                                  verticalAlign: "middle",
                                                  mr: -0.5,
                                              }}
                                          >
                                              keyboard_arrow_right
                                          </Icon>
                                      )}
                                  </MKTypography>
                              );
                          });
                  }

                  return template;
              })
            : null
    );

    // Dropdown menu for the nested dropdowns
    const nestedDropdownMenu = (
        <Popper
            anchorEl={nestedDropdown}
            popperRef={null}
            open={Boolean(nestedDropdown)}
            placement="right-start"
            transition
            style={{ zIndex: 10 }}
            onMouseEnter={() => {
                setNestedDropdown(nestedDropdownEl);
            }}
            onMouseLeave={() => {
                setNestedDropdown(null);
                setNestedDropdownName("");
                setDropdown(null);
            }}
        >
            {({ TransitionProps }) => (
                <Grow
                    {...TransitionProps}
                    sx={{
                        transformOrigin: "left top",
                        background: ({ palette: { white } }) => white.main,
                    }}
                >
                    <MKBox ml={2.5} mt={-2.5} borderRadius="lg">
                        <MKBox
                            shadow="lg"
                            borderRadius="lg"
                            py={1.5}
                            px={1}
                            mt={2}
                        >
                            {renderNestedRoutes}
                        </MKBox>
                    </MKBox>
                </Grow>
            )}
        </Popper>
    );

    return (
        <Container
            sx={sticky ? { position: "sticky", top: 0, zIndex: 10 } : null}
        >
            <MKBox
                py={1}
                px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
                my={relative ? 0 : 2}
                mx={relative ? 0 : 3}
                width={relative ? "100%" : "calc(100% - 48px)"}
                borderRadius="xl"
                shadow={transparent ? "none" : "md"}
                color={light ? "white" : "dark"}
                position={relative ? "relative" : "absolute"}
                left={0}
                zIndex={3}
                sx={({
                    palette: { transparent: transparentColor, white },
                    functions: { rgba },
                }) => ({
                    backgroundColor: transparent
                        ? transparentColor.main
                        : rgba(white.main, 0.8),
                    backdropFilter: transparent
                        ? "none"
                        : `saturate(200%) blur(30px)`,
                })}
            >
                <MKBox display="flex" alignItems="center">
                    <MKBox
                        id="mobile-menu"
                        display={{ xs: "inline-block", lg: "none" }}
                        lineHeight={0}
                        pr={2}
                        color={transparent ? "white" : "inherit"}
                        sx={{ cursor: "pointer" }}
                        onClick={openMobileNavbar}
                    >
                        {mobileNavbar ? <Close /> : <Menu />}
                    </MKBox>
                    <MKBox
                        id="brand"
                        component={Link}
                        to="/"
                        lineHeight={1}
                        py={transparent ? 1.5 : 0.75}
                        pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
                        onClick={menuItemClicked}
                    >
                        <MKTypography
                            variant="h3"
                            fontWeight="bold"
                            color={light ? "white" : "dark"}
                        >
                            {brand}
                        </MKTypography>
                    </MKBox>
                    <MKBox
                        id="menu"
                        color="inherit"
                        display={{ xs: "none", lg: "flex" }}
                        ml="1em"
                        width="100%"
                    >
                        {renderNavbarItems}
                    </MKBox>
                </MKBox>
                <MKBox
                    bgColor={transparent ? "white" : "transparent"}
                    shadow={transparent ? "lg" : "none"}
                    borderRadius="xl"
                    px={transparent ? 2 : 0}
                >
                    {mobileView && (
                        <DefaultNavbarMobile
                            routes={routes}
                            open={mobileNavbar}
                            onMenuItemClicked={menuItemClicked}
                        />
                    )}
                </MKBox>
            </MKBox>
            {dropdownMenu}
            {nestedDropdownMenu}
        </Container>
    );
}

function Spacer() {
    return <Box flexGrow={1} />;
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
    transparent: false,
    light: false,
    sticky: false,
    relative: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
    brand: PropTypes.any,
    routes: PropTypes.arrayOf(PropTypes.shape).isRequired,
    transparent: PropTypes.bool,
    light: PropTypes.bool,
    sticky: PropTypes.bool,
    relative: PropTypes.bool,
};

export default DefaultNavbar;
