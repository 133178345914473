import { Box, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { App } from "./App";
import ErrorPage from "./Pages/ErrorPage";
import { AllRoutes } from "./Routes/AllRoutes";
import { gaInit } from "./Util/GA";
import sentryInit from "./Util/Sentry";
import "./index.css";
import theme from "./material-kit/theme";

sentryInit();
gaInit();
const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: AllRoutes,
    },
]);

root.render(
    <React.StrictMode>
        <HelmetProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box className="App" sx={{ padding: "0.2em" }}>
                    <RouterProvider router={router} />
                </Box>
            </ThemeProvider>
        </HelmetProvider>
    </React.StrictMode>
);
