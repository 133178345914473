/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unescaped-entities */
import screenshot from "src/images/PaintMatch/3.webp";
import BlogPage from "../../../Components/BlogPage";
import InternalLink from "../../../Components/InternalLink";
import Paragraph from "../../../Components/Typography/Paragraph";
import YoutubeEmbed from "../../../Components/YoutubeEmbed";
import { PaintMatchReleaseRoute } from "../../../Routes/MenuRoutes";

export default function PaintMatchRelease() {
    return (
        <BlogPage
            title="Paint Match released"
            description="Paint blending puzzle game Paint Match is available for free"
            thumbnail={screenshot}
            route={PaintMatchReleaseRoute}
            date="2023-05-08"
        >
            <Paragraph>
                When I was learning to paint minis, I wished there was a tool
                for me to practice mixing colors I needed from primary pigments
                without wasting actual paints. I searched online, found nothing
                that satisfied me and decided to build my own. The game is
                called Paint Match, it's free and open source. In the game, you
                subtractively mix primary colors to get the target color. I use
                CMYK model for blending. I'm sharing it here hoping it can help
                someone to become better artist at zero cost (there's no ads
                too).
            </Paragraph>
            <YoutubeEmbed videoId="2_frnlZEvt0?si=GSgHynna2PR39HiV" />
            <Paragraph>
                Check out{" "}
                <InternalLink to="/games/PaintMatch" text="Paint Match" /> in my
                portfolio.
            </Paragraph>
        </BlogPage>
    );
}
