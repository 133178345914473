/* eslint-disable react/no-unescaped-entities */
import BlogPage from "../../../Components/BlogPage";
import InternalLink from "../../../Components/InternalLink";
import Paragraph from "../../../Components/Typography/Paragraph";
// eslint-disable-next-line import/no-unresolved
import screenshot from "src/images/BigLapStopwatch/phone.webp";
import { BlogBigLapStopwatchReleaseRoute } from "../../../Routes/MenuRoutes";

export default function BlogBigLapStopwatch() {
    return (
        <BlogPage
            title="Introducing Big Lap Stopwatch Android app"
            description="I released a free and lightweight stopwatch app for Android"
            thumbnail={screenshot}
            route={BlogBigLapStopwatchReleaseRoute}
            date="2020-11-08"
        >
            <Paragraph>
                I was searching for a stopwatch app that wasn't overloaded with
                features, had a large font, and kept the screen on while
                running. To my surprise, I couldn't find one. So, I built my
                own. It does just one thing: counts time with laps, but it does
                that well. I'm releasing the app on the Android Play Store for
                free.
            </Paragraph>
            <Paragraph>
                I use this stopwatch app every day to track my workouts and
                other activities. It's also helpful when I want to display time
                while recording a video.
            </Paragraph>
            <Paragraph>
                Check out{" "}
                <InternalLink
                    to="/apps/BigLapStopwatch"
                    text="Big Lap Stopwatch"
                />{" "}
                in my portfolio.
            </Paragraph>
        </BlogPage>
    );
}
