import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';


export const rootUrl = 'https://ombosoft.com';

const CanonicalLink = () => {
  const location = useLocation();
  const canonicalURL = `${rootUrl}${location.pathname}`;

  return (
    <Helmet>
      <link rel="canonical" href={canonicalURL} />
    </Helmet>
  );
};

export default CanonicalLink;