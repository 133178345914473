// eslint-disable-next-line import/no-unresolved
import screenshot from "src/images/GlowBlitz/screenshot700.webp";

import ItchIoBadge from "../../Components/ItchIoBadge";
import PortfolioPage from "../../Components/PortfolioPage";
import Bullets from "../../Components/Typography/Bullets";
import Paragraph from "../../Components/Typography/Paragraph";
import MKTypography from "../../material-kit/MKTypography";
import { GlowBlitzRoute } from "../../Routes/MenuRoutes";

export default function GlowBlitz({ headerOnly }) {
    return (
        <PortfolioPage
            title="GlowBlitz"
            subtitle="Pong with a twist"
            description="Classic game reimagined and infused with air hockey"
            screenshot={screenshot}
            videoId="LkGNJ08XpHo?si=XXZmqXiJINzDiC5Z"
            route={GlowBlitzRoute}
            headerOnly={headerOnly}
        >
            <Paragraph>
                This is a tribute to the classic game Pong, enhanced with modern features:
            </Paragraph>
            <Bullets>
                <li>The ball can spin, giving skillful players an advantage.
                </li>
                <li>Paddles are curved, enabling strategic side bounces.</li>
                <li>Players can push the ball, similar to air hockey.</li>
                <li>Boosters change the ball speed or paddle size.</li>
                <li>Enjoy the game with a friend on the same computer (single-player mode is also available).</li>
                <li>Free and no ads.</li>
            </Bullets>
            <ItchIoBadge itchId={2375710} />
            <MKTypography variant="h3" component="div" pt={2}>
                Credits
            </MKTypography>
            <Bullets>
                <li>
                    Game design and coding by <b>Shurick (Ombosoft)</b>
                </li>
                <li>
                    Music by tidnmusic
                </li>
            </Bullets>
        </PortfolioPage>
    );
}
