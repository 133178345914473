import { Box, Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
// import "./App.css";
import { CookieConsent } from "react-cookie-consent";
import { Footer } from "./Components/Footer";
import { NavBar } from "./Components/NavBar/NavBar";
import CanonicalLink from "./Util/CanonicalLink";
import MKTypography from "./material-kit/MKTypography";

export function App() {
    return (
        <Box>
            <CanonicalLink />
            <NavBar />
            <Container>
                <Box my={4} id="appbox" justifyContent="center" display="flex">
                    <Outlet />
                </Box>
            </Container>
            <Footer />
            <CookieConsent
                location="bottom"
                buttonText="Sure"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
            >
                <MKTypography variant="body2" color="white">
                    This website uses cookies to enhance the user experience.
                </MKTypography>
            </CookieConsent>
        </Box>
    );
}
