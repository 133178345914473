// eslint-disable-next-line import/no-unresolved
import logoUrl from "src/images/ombosoft.png";


import { Box } from "@mui/material";


export default function OmbosoftIcon({round}) {
    const radius = round ? "1em" : "0.1em";
    const radiusProps = {borderRadius: radius};
    return (
        <Box
            component="img"
            sx={{
                width: "1em",
                height: "1em",
                ...radiusProps
                
            }}
            src={logoUrl}
            alt="Ombosoft logo"
        />
    );
}
