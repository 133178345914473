/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unescaped-entities */
import screenshot from "src/images/PaintMatch/3.webp";
import BlogPage from "../../../Components/BlogPage";
import InternalLink from "../../../Components/InternalLink";
import Paragraph from "../../../Components/Typography/Paragraph";
import { BlogBigLapStopwatchOpensourceRoute } from "../../../Routes/MenuRoutes";

export default function BigLapStopwatchOpensource() {
    return (
        <BlogPage
            title="Big Lap Stopwatch on GitHub"
            description="I'm releasing source code of my stopwatch Android app"
            thumbnail={screenshot}
            route={BlogBigLapStopwatchOpensourceRoute}
            date="2023-10-24"
        >
            <Paragraph>
                Google made me rebuild the app with newer API level. I
                decided it's a good oppotrunity to release the source code on
                GitHub. The app definitely works as it passed the Google review
                and I personally use it every day. Enjoy!
            </Paragraph>
            <Paragraph>
                Check out{" "}
                <InternalLink
                    to="/apps/BigLapStopwatch"
                    text="Big Lap Stopwatch"
                />{" "}
                in my portfolio.
            </Paragraph>
        </BlogPage>
    );
}
