/* eslint-disable react/no-unescaped-entities */
import OpenGraph from "../Components/OpenGraph";
import PageBox from "../Components/PageBox";
import Bullets from "../Components/Typography/Bullets";
import Paragraph from "../Components/Typography/Paragraph";
import SubHeader from "../Components/Typography/SubHeader";
import { PrivacyRoute } from "../Routes/HiddenRoutes";
import { formatDateToLocale } from "../Util/DateFormatter";
import MKTypography from "../material-kit/MKTypography";
// eslint-disable-next-line import/no-unresolved
import logoUrl from "src/images/ombosoft.png";
import { emailAddress } from "./AboutPage";

export function PrivacyPage() {
    const modifiedDate = "2023-10-03";
    return (
        <>
            <OpenGraph
                title="ombosoft.com privacy policy"
                thumbnail={logoUrl}
                route={PrivacyRoute}
                date={modifiedDate}
            />
            <PageBox>
                <MKTypography variant="h1" color="info" textGradient>
                    ombosoft.com Privacy Policy
                </MKTypography>
                <MKTypography variant="body2" color="secondary">
                    <time dateTime={modifiedDate}>
                        Last Updated: {formatDateToLocale(modifiedDate)}
                    </time>
                </MKTypography>

                <Paragraph>
                    This Privacy Policy outlines how we collect, use, protect,
                    and disclose your information when you visit or use our
                    website.
                </Paragraph>

                <SubHeader>1. Information We Collect</SubHeader>

                <Paragraph>
                    <em>Directly Provided Information:</em> We may collect
                    information you provide directly to us. For instance, when
                    you contact us or sign up for our newsletter, you may
                    provide us with your name, email address, and other relevant
                    details.
                </Paragraph>

                <Paragraph>
                    <em>Automated Data Collection:</em> When you visit our site,
                    we may automatically collect certain information about your
                    device and usage of our site. This includes:
                </Paragraph>
                <Bullets>
                    <li>IP address</li>
                    <li>Browser type</li>
                    <li>Operating system</li>
                    <li>Access times</li>
                    <li>Pages viewed</li>
                    <li>Links clicked</li>
                </Bullets>

                <Paragraph>
                    <em>Third-party services:</em> We use Google Analytics and
                    Sentry to monitor and analyze web traffic and can be used to
                    keep track of user behavior.
                </Paragraph>

                <SubHeader>2. How We Use Your Information</SubHeader>

                <Bullets>
                    <li>Operate, maintain, and improve our website.</li>
                    <li>
                        Respond to comments and questions and provide customer
                        service.
                    </li>
                    <li>
                        Send information related to accounts and services,
                        including confirmations, invoices, technical notices,
                        updates, security alerts, and support and administrative
                        messages.
                    </li>
                    <li>
                        Monitor and evaluate usage and trends of our website.
                    </li>
                </Bullets>

                <SubHeader>3. How We Share Your Information</SubHeader>

                <Paragraph>
                    We do not sell, trade, or otherwise transfer to outside
                    parties your Personally Identifiable Information unless we
                    provide users with advance notice. We may release
                    information when its release is appropriate to comply with
                    the law, enforce our site policies, or protect ours or
                    others' rights, property, or safety.
                </Paragraph>

                <Bullets>
                    <li>
                        <em>Third-party service providers:</em> We may share
                        information with third-party service providers that
                        support various aspects of our business operations
                        (e.g., analytics and website functionality).
                    </li>
                    <li>
                        <em>Legal compliance and protection:</em> We may
                        disclose information about you if we believe such
                        disclosure is necessary to:
                    </li>
                </Bullets>
                <Bullets>
                    <li>
                        Comply with laws or respond to lawful requests and legal
                        processes.
                    </li>
                    <li>
                        Protect or defend the rights, safety, or property of
                        Ombosoft, our users, or any person.
                    </li>
                </Bullets>

                <SubHeader>4. Third-party Tools and Links</SubHeader>

                <Paragraph>
                    Our website includes features from third parties, such as
                    Google Analytics and Sentry. These third-party service
                    providers may use cookies or other tracking technologies to
                    collect information about you when you use our website. We
                    do not control these third parties' tracking technologies or
                    how they may be used.
                </Paragraph>

                <Paragraph>
                    Our site may also contain links to third-party websites. We
                    are not responsible for the privacy practices of these
                    external sites.
                </Paragraph>

                <SubHeader>5. Security</SubHeader>

                <Paragraph>
                    Your security is essential to us. We take reasonable
                    measures to help protect your information from loss, theft,
                    misuse, unauthorized access, disclosure, alteration, and
                    destruction.
                </Paragraph>

                <SubHeader>6. Children's Privacy</SubHeader>

                <Paragraph>
                    Our site is not intended for use by children under the age
                    of 13. We do not knowingly collect personal information from
                    children under 13. If we find out we have gathered such
                    information, we will take steps to delete it as soon as
                    possible.
                </Paragraph>

                <SubHeader>7. Changes to this Privacy Policy</SubHeader>

                <Paragraph>
                    We may update our Privacy Policy. If we make changes, we
                    will notify you by revising the "Last Updated" date at the
                    beginning of this policy.
                </Paragraph>

                <SubHeader>8. Contact Us</SubHeader>

                <Paragraph>
                    For any questions regarding this Privacy Policy or our
                    privacy practices, please contact us at {emailAddress}.
                </Paragraph>
            </PageBox>
        </>
    );
}
